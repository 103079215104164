import { Link } from "@remix-run/react";
import parse from "html-react-parser";

import { useLanguage } from "~/context/LanguageContext";
import { getLocalizedPagePath } from "~/helpers/navigationHelper";

import i18n from "./i18n";

export default function CallToAction({
  fullWidth = false,
  backgroundImage = "/img/cta/cta_bg_5.webp",
}: {
  fullWidth?: boolean;
  backgroundImage?: string;
}) {
  const { lang } = useLanguage();
  const t = i18n[lang];

  return (
    <section>
      <div className={fullWidth ? "" : "container"}>
        <div
          className="cs-cta cs-style1 text-center cs-shape_wrap_1 cs-position_1"
          style={{ backgroundImage: `url('${backgroundImage}')` }}
        >
          <div className="cs-shape_1"></div>
          <div className="cs-shape_1"></div>
          <div className="cs-shape_1"></div>
          <div className="cs-cta_in">
            <h2 className="cs-cta_title cs-semi_bold cs-m0">
              {parse(t.title)}
            </h2>
            <div className="cs-height_70 cs-height_lg_30"></div>
            <Link
              to={getLocalizedPagePath("/contact")}
              className="cs-text_btn wow fadeInUp"
              data-wow-duration="0.8s"
              data-wow-delay="0.2s"
              style={{ fontSize: "1.5rem" }}
            >
              <span>{t.meeting}</span>
              <svg
                width="26"
                height="12"
                viewBox="0 0 26 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.5307 6.53033C25.8236 6.23744 25.8236 5.76256 25.5307 5.46967L20.7577 0.696699C20.4648 0.403806 19.99 0.403806 19.6971 0.696699C19.4042 0.989593 19.4042 1.46447 19.6971 1.75736L23.9397 6L19.6971 10.2426C19.4042 10.5355 19.4042 11.0104 19.6971 11.3033C19.99 11.5962 20.4648 11.5962 20.7577 11.3033L25.5307 6.53033ZM0.000366211 6.75H25.0004V5.25H0.000366211V6.75Z"
                  fill="currentColor"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
